import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Form, Table, Select, DatePicker, TimePicker, Badge } from "antd"
import { Container, Row, Button, Col } from "reactstrap"
import Breadcrumb from "../../../components/Common/Breadcrumb"
import dayjs from "dayjs"
import { ClockCircleOutlined } from "@ant-design/icons"
//i18n
import { withTranslation } from "react-i18next"
const { Option } = Select

const getRandomColor = () => {
  const letters = "0123456789ABCDEF"
  let color = "#"
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

const CavUsageReportList = props => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const columns = [
    {
      title: "Room",
      dataIndex: "room",
      render: (text, record) => (
        <>
          {text}
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: record.colorDot,
              marginLeft: "8px",
            }}
          />
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Hours Usage",
      dataIndex: "hoursusage",
    },
  ]
  const data = []
  for (let i = 0; i < 2; i++) {
    data.push({
      key: i,
      room: "Living Room",
      date: `11/2/2023`,
      hoursusage: `${7 + i} Hrs`,
      colorDot: getRandomColor(),
    })
  }

  document.title = "Usage Report Details | TRUST"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb
            title="Cave"
            dashboardRoute="/cave-dashboard"
            breadcrumbItem="Usage Report Details"
          />
          <Row>
            <Col>
              <Form
                form={form}
                layout="vertical"
                size="large"
                // initialValues={{
                //   requiredMarkValue: requiredMark,
                // }}
                // onValuesChange={onRequiredTypeChange}
                // requiredMark={
                //   requiredMark === "customize"
                //     ? customizeRequiredMark
                //     : requiredMark
                // }
              >
                <Form.Item>
                  <Form.Item
                    name="universityselect"
                    label=""
                    style={{
                      display: "inline-block",
                      width: "calc(20% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <Select placeholder="Please select a University">
                      <Option value="University Of Leads">
                        University Of Leads
                      </Option>
                      <Option value="university of amsterdam">
                        University Of Amsterdam
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="fromdate"
                    label=""
                    style={{
                      display: "inline-block",
                      width: "calc(10% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <DatePicker placeholder="From Date" />
                  </Form.Item>
                  <Form.Item
                    name="dateto"
                    label=""
                    style={{
                      display: "inline-block",
                      width: "calc(10% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <DatePicker placeholder="Date to" />
                  </Form.Item>
                  <Form.Item
                    name="starttime"
                    label=""
                    style={{
                      display: "inline-block",
                      width: "calc(10% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <TimePicker
                      placeholder="Start Time"
                      defaultValue={dayjs("12:08:23", "HH:mm:ss")}
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    name="endtime"
                    label=""
                    style={{
                      display: "inline-block",
                      width: "calc(10% - 8px)",
                      margin: "0 8px",
                    }}
                  >
                    <TimePicker
                      placeholder="End Time"
                      defaultValue={dayjs("12:08:23", "HH:mm:ss")}
                      size="large"
                    />
                  </Form.Item>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row>
            <Table
              scroll={{
                x: 1300,
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {
                    navigate("/cave-usage-report-details")
                    console.log("event-->", rowIndex)
                  },
                }
              }}
              columns={columns}
              dataSource={data}
            />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CavUsageReportList.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CavUsageReportList)
