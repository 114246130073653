import { takeEvery, put, call } from "redux-saga/effects"
import { $host, $authHost } from "store/axios"
import {
  actionTypes,
  getApplicationClientsSuccess,
  getClientDetailsSuccess,
  createClientsSuccess,
  updateClientsSuccess,
  resetAppClientsStates,
} from "./action"
import { notification } from "antd"

// get All clients
function* getApplicationClients(action) {
  const { application_type } = action
  try {
    const response = yield $authHost.get(
      `clients?application_type=${application_type}`
    )
    console.log('responseresponse', response)
    yield put(
      getApplicationClientsSuccess({
        applicationType: application_type,
        data: response?.data?.data?.clients,
      })
    )
    yield put(resetAppClientsStates())
  } catch (error) {
    yield console.error(error)
  }
}

// create client
function* createClients(action) {
  const { payload } = action
  // const clientPayload = {
  //   type: payload.typeId,
  //   client_name: payload.client_name,
  //   first_name: payload.firstname,
  //   last_name: payload.lastname,
  //   username: payload.username,
  //   email: payload.email,
  //   password: payload.confirmPassword,
  //   mobile: payload.contactnumber,
  //   address_line1: payload.AddressLine1,
  //   address_line2: payload.AddressLine2,
  //   city: payload.city,
  //   state: payload.state,
  //   post_code: payload.postcode,
  //   country: payload.country,
  //   access_code: payload.access_code,
  //   logo: payload.logo,
  //   geofencing_radius: payload.geofencing_radius,
  //   primary_color: payload.primarycolor,
  //   primary_dark_color: payload.primarydark,
  //   secondary_color: payload.secondarycolor,
  //   accent_color: payload.accentcolor,
  //   background_color: payload.backgroundcolor,
  //   title_text_color: payload.titleTextColor,
  //   header_text_color: payload.headerTextColor,
  //   button_text_color: payload.buttonTextColor,
  //   hub_ip: payload.hupIpAddress,
  //   hub_port: payload.hubport,
  //   hub_username: payload.hubusername,
  //   hub_password: payload.hubconfirmPassword,
  // }

  const clientPayload = new FormData()
  clientPayload.append('type', payload.typeId)
  clientPayload.append('client_name', payload.client_name)
  clientPayload.append('first_name', payload.firstname)
  clientPayload.append('last_name', payload.lastname)
  clientPayload.append('username', payload.username)
  clientPayload.append('email', payload.email)
  clientPayload.append('password', payload.confirmPassword)
  clientPayload.append('mobile', payload.contactnumber)
  clientPayload.append('address_line1', payload.AddressLine1)
  clientPayload.append('address_line2', payload.AddressLine2)
  clientPayload.append('city', payload.city)
  clientPayload.append('state', payload.state)
  clientPayload.append('post_code', payload.postcode)
  clientPayload.append('country', payload.country)
  clientPayload.append('access_code', payload.access_code)
  clientPayload.append('logo', payload.logo)
  clientPayload.append('geofencing_radius', payload.geofencing_radius)
  clientPayload.append('primary_color', payload.primarycolor)
  clientPayload.append('primary_dark_color', payload.primarydark)
  clientPayload.append('secondary_color', payload.secondarycolor)
  clientPayload.append('accent_color', payload.accentcolor)
  clientPayload.append('background_color', payload.backgroundcolor)
  clientPayload.append('title_text_color', payload.titleTextColor)
  clientPayload.append('header_text_color', payload.headerTextColor)
  clientPayload.append('button_text_color', payload.buttonTextColor)
  if (payload.typeId === 2) {
    clientPayload.append('hub_ip', payload.hupIpAddress);
    clientPayload.append('hub_port', payload.hubport);
    clientPayload.append('hub_username', payload.hubusername);
    clientPayload.append('hub_password', payload.hubconfirmPassword);
  }


  try {
    const response = yield $authHost.post(`clients`, clientPayload)
    if (response.data.code === 200) {
      notification.success({
        message: response.data.message,
        description: response.data.description,
      })
      yield put(createClientsSuccess(true))
      yield put(resetAppClientsStates())
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetAppClientsStates())
    }
  }
}

// update client
function* updateClients(action) {
  const { payload } = action
  const clientPayload = {
    type: payload.typeId,
    client_name: payload.client_name,
    address_line1: payload.AddressLine1,
    address_line2: payload.AddressLine2,
    mobile: payload.contactnumber,
    email: payload.email,
    city: payload.city,
    state: payload.state,
    post_code: payload.postcode,
    country: payload.country,
    geofencing_radius: payload.geofencing_radius,
    primary_color: payload.primarycolor,
    primary_dark_color: payload.primarydark,
    secondary_color: payload.secondarycolor,
    accent_color: payload.accentcolor,
    background_color: payload.backgroundcolor,
    title_text_color: payload.titleTextColor,
    header_text_color: payload.headerTextColor,
    button_text_color: payload.buttonTextColor,
    hub_ip:
      payload.typeId === 1
        ? null
        : payload.typeId === 2 && payload.hupIpAddress,
    hub_port:
      payload.typeId === 1 ? null : payload.typeId === 2 && payload.hubport,
    hub_username:
      payload.typeId === 1 ? null : payload.typeId === 2 && payload.hubusername,
    hub_password:
      payload.typeId === 1
        ? null
        : payload.typeId === 2 && payload.hubconfirmPassword,
  }

  // const formData = new FormData();
  // formData.append('type', payload.typeId);
  // formData.append('client_name', payload.client_name);
  // formData.append('address_line1', payload.AddressLine1);
  // formData.append('address_line2', payload.AddressLine2);
  // formData.append('mobile', payload.contactnumber);
  // formData.append('email', payload.email);
  // formData.append('city', payload.city);
  // formData.append('state', payload.state);
  // formData.append('post_code', payload.postcode);
  // formData.append('country', payload.country);
  // formData.append('geofencing_radius', payload.geofencing_radius);
  // formData.append('primary_color', payload.primarycolor);
  // formData.append('primary_dark_color', payload.primarydark);
  // formData.append('secondary_color', payload.secondarycolor);
  // formData.append('accent_color', payload.accentcolor);
  // formData.append('background_color', payload.backgroundcolor);
  // formData.append('title_text_color', payload.titleTextColor);
  // formData.append('header_text_color', payload.headerTextColor);
  // formData.append('button_text_color', payload.buttonTextColor);

  // formData.append('hub_ip', payload.typeId === 1 ? '' : (payload.typeId === 2 ? payload.hupIpAddress || '' : ''));
  // formData.append('hub_port', payload.typeId === 1 ? '' : (payload.typeId === 2 ? payload.hubport || '' : ''));
  // formData.append('hub_username', payload.typeId === 1 ? '' : (payload.typeId === 2 ? payload.hubusername || '' : ''));
  // formData.append('hub_password', payload.typeId === 1 ? '' : (payload.typeId === 2 ? payload.hubconfirmPassword || '' : ''));

  try {
    const response = yield $authHost.post(
      `clients/${payload.client_id}`,
      clientPayload
    )
    if (response.data.code === 200) {
      notification.success({
        message: response.data.message,
        description: response.data.description,
      })
      yield put(updateClientsSuccess(true))
      yield put(resetAppClientsStates())
    }
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetAppClientsStates())
    }
  }
}

// client Status change
function* changeClientStatus(action) {
  const { payload } = action
  try {
    const response = yield $authHost.put(
      `client?application_type=${payload.application_type}/${payload.org_id}/status/${payload.value}`
    )
    notification.success({
      message: response.data.message,
      description: response.data.description,
    })
    yield put(getApplicationClients(payload.application_type))
  } catch (error) {
    if (error.response && error.response.data) {
      const { code, message, description } = error.response.data
      const errorPreview = { code, message, description }
      notification.error({
        message: errorPreview.message,
        description: errorPreview.description,
      })
      yield put(resetAppClientsStates())
    }
  }
}

// get single client info
function* getApplicationClientsDetails(action) {
  const { client_id, application_type } = action.payload

  try {
    const response = yield $authHost.get(
      `/clients/${client_id}?application_type=${application_type}`
    )

    yield put(
      getClientDetailsSuccess({
        applicationType: application_type,
        data: response.data.data.client,
      })
    )
  } catch (error) {
    yield console.error(error)
  }
}

function* AppClientsSaga() {
  yield takeEvery(actionTypes.GET_APPLICATION_CLIENTS, getApplicationClients)
  yield takeEvery(actionTypes.CREATE_CLIENT, createClients)
  yield takeEvery(actionTypes.UPDATE_CLIENT, updateClients)
  yield takeEvery(actionTypes.GET_CLIENT_INFO, getApplicationClientsDetails)
  yield takeEvery(actionTypes.CLIENT_STATUS_CHANGE, changeClientStatus)
}

export default AppClientsSaga
