import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Upload, Button, Form, Input, Alert, Avatar } from "antd"
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import { connect, useDispatch, useSelector } from "react-redux"
import withRouter from "components/Common/withRouter"
import Breadcrumb from "../../components/Common/Breadcrumb"
import { updateUserProfile, getUserProfile } from "store/actions"

const UserProfile = props => {
  const dispatch = useDispatch()
  const { userProfile, loading } = useSelector(state => state.ProfileReducer)

  const [form] = Form.useForm(); 
  const [image, setImage] = useState('');

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(userProfile).length > 0) {
      form.setFieldsValue({ 
        first_name: userProfile.first_name,
        last_name: userProfile.last_name,
        phone: userProfile.mobile,
        username: userProfile.username,
        email: userProfile.email,
        profile_image: userProfile.profile_image
      });
      setImage(userProfile.profile_image);
    }
  }, [userProfile, form]); 

  const handleUploadChange = (info) => {
    setImage(URL.createObjectURL(info.file));
  }

  const handleSubmit = (values) => {
    dispatch(updateUserProfile(values));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="TRUST" breadcrumbItem="Profile" />
          <Row>
            <Col lg="12">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Form.Item label="Profile Image">
                  <Upload
                    name="profile_image"
                    listType="picture"
                    maxCount={1}
                    onChange={handleUploadChange}
                    beforeUpload={() => false}
                    showUploadList={false}
                  >
                    <Avatar
                      src={image}
                      alt="Profile"
                      size={90}
                    />
                  </Upload>
                </Form.Item>
                <Form.Item label="Email" name="email">
                  <Input disabled />
                </Form.Item>
                <Form.Item label="First Name" name="first_name">
                  <Input />
                </Form.Item>
                <Form.Item label="Last Name" name="last_name">
                  <Input />
                </Form.Item>
                <Form.Item label="Mobile" name="phone">
                  <Input />
                </Form.Item>
                <Form.Item label="Username" name="username">
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button disabled={loading} type="primary" htmlType="submit">
                    Edit Profile
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
}

const mapStatetoProps = state => {
  const { success } = state.ProfileReducer
  return { success }
}

export default withRouter(connect(mapStatetoProps, {})(UserProfile))